import './recen.css';
import './RecPopup.css';
import './Animation.css';
import { useState } from 'react';
import { useLanguage } from '../../App';  // Import the useLanguage hook
import img1 from './imgRecen/aragin.jpg';
import img2 from './imgRecen/aragin1.jpg';
import img3 from './imgRecen/arajin2.jpg';
import img4 from './imgRecen/erkrod.png';
import img5 from './imgRecen/erkrod1.png';
import img6 from './imgRecen/erkrod2.png';
import img7 from './imgRecen/errord.jpg';
import img8 from './imgRecen/errord1.jpg';
import img9 from './imgRecen/errord2.jpg';
import img10 from './imgRecen/chorord.png';
import img11 from './imgRecen/hingerord.png';
import img12 from './imgRecen/vecerord.png';
import img13 from './imgRecen/yoterord.png';
import img14 from './imgRecen/8erord.png';
import img15 from './imgRecen/9erord.png';
import img16 from './imgRecen/10erord.png';
import img17 from './imgRecen/11rd.png';
import img18 from './imgRecen/11.1rd.png';
import img19 from './imgRecen/11.2rd.png';
import img20 from './imgRecen/11.3rd.png';
import fail1 from './FAIL/ASUG_1.docx';
import fail2 from './FAIL/Instrukcya.docx';
import fail3 from './FAIL/Trebavaniye.docx';
import fail4 from './FAIL/User.docx';
import img21 from './imgRecen/save.svg'
import img22 from './imgRecen/Userimg.png'

const imgArray = [img1, img2];
const imgArray1 = [img3, img4,];
const imgArray2 = [img5, img6];
const imgArray3 = [img18, img19, img20];

const translations = {
  en: {
    recentWorks: "RECENT WORKS",
    recentWorksContent: "Since 2014 We have implemented more than 30 assignments delivered to 25 customers. 16 projects were carried out in web programming, graphic design and system integration services. 9 companies has benefited from digital marketing services, including design of strategy, branding etc. 6 companies benefited from server systems providing, web hosting and cloud automation services.",
    readMore: "Read more...",
    showLess: "Show less...",
    showMore: "Show more...",
    footer: "Copyright © 2024 All rights reserved | This website is made with by ITFIVE",
    ldtTech: "LDT Technology",
    ldtTechContent: "Design and Creation of LDT-technology.com website, website security maintenance and support.",
    utaxi: "UTAXI",
    utaxiContent: "Online cab dispatching platform with own map, routing and navigation system.",
    unionOfInformedCitizens: "Union of Informed Citizens",
    unionOfInformedCitizensContent: "Design and Creation of course.uicarmenia.org and umdimel.am websites, SEO, website security maintenance and support.",
    cilicia: "CILICIA",
    ciliciaContent: "Website about a Historical movie",
    sixtAm: "SIXT.am",
    sixtAmContent: "Famous car rentals service's website in Armenia",
    koharBlog: "KOHAR blog",
    koharBlogContent: "Music Blog, Symphony Orchestra & Choir Blog website",
    affordableLuxuryTravel: "Affordable Luxury Travel",
    affordableLuxuryTravelContent: "Luxury tour operator website",
    meliq: "MELIQ",
    meliqContent: "Website for Oriental jewelry Making Company",
    yungo: "YUNGO",
    yungoContent: "VoIP Service mobile app Development",
    pixpops: "Pixpops",
    pixpopsContent: "Image ordering platform which enables users to choose photos to be shipped to decorate their walls without hammering any nails. Payment System (PayPal, Stripe) UPS Integration",
    friendsWash: "FRIENDS WASH",
    friendsWashContent: "Online order processing system Order processing system (Admin dashboard)",
    asurg: "<<АСУРГ>>",
    asurgContent: "Automatic Gas Consumption Metering System",
    popupAsurg: {
      point1: "1. About the program: Exclusive rights to the software 'ASURG' belong to the Limited Liability Company 'ITFive'",
      point2: "2. The cost is calculated individually. To determine the cost, you can contact by phone +37495235294 / email info@itfive.am (Specify contacts to determine the cost)",
      point3: "3. About functional characteristics",
      point4: "4. The software is an Internet service, there are additional requirements for installing the program on the part of the user not required",
      point5: "5. Software operating instructions (user manual)."
    },
    friendsWashDetails: {
      actualSituation: "Actual situation with car washing services and clients on the map",
      clientsList: "Clients list (with filtering tools by several criteria)",
      liveOrders: "Live orders list with additional information",
      cooperationRequests: "Cooperation request list (from car washing services)",
      feedbackRating: "Feedback and rating system both for clients and washing services",
      bookingSystem: "Booking system",
      blackList: "Black list",
      notifications: "Notifications system",
      settings: "Settings",
      onlineAppFunctionality: "Washing services and clients' online application functionality",
      mapView: "Washing services view on the map",
      clientLocation: "To determine client’s location on the maps to show closest washing service",
      bookService: "To book a service",
      createOrder: "To create an order immediately based on the current location",
      chooseService: "To choose the washing service from the list (listed by distance from the client)",
      viewServiceLists: "To view service lists and prices for every washing service",
      chooseTime: "To choose comfortable time from the timetable",
      writeFeedback: "To write a feedback and rate the washing service"
    }
  },
  ru: {
    recentWorks: "ПОСЛЕДНИЕ РАБОТЫ",
    recentWorksContent: "С 2014 года мы выполнили более 30 заданий, доставленных 25 клиентам. 16 проектов были выполнены в области веб-программирования, графического дизайна и системной интеграции. 9 компаний воспользовались услугами цифрового маркетинга, включая разработку стратегии, брендинг и т. д. 6 компаний воспользовались услугами предоставления серверных систем, веб-хостинга и облачной автоматизации.",
    readMore: "Читать далее...",
    showLess: "Показать меньше...",
    showMore: "Показать больше...",
    footer: "Авторское право © 2024 Все права защищены | Этот сайт сделан ITFIVE",
    ldtTech: "LDT Technology",
    ldtTechContent: "Дизайн и создание веб-сайта LDT-technology.com, обслуживание и поддержка безопасности веб-сайта.",
    utaxi: "UTAXI",
    utaxiContent: "Онлайн-платформа диспетчеризации такси с собственной картой, маршрутизацией и навигационной системой.",
    unionOfInformedCitizens: "Союз Информированных Граждан",
    unionOfInformedCitizensContent: "Дизайн и создание сайтов course.uicarmenia.org и umdimel.am, SEO, обслуживание и поддержка безопасности сайта.",
    cilicia: "CILICIA",
    ciliciaContent: "Сайт о историческом фильме",
    sixtAm: "SIXT.am",
    sixtAmContent: "Сайт известной службы проката автомобилей в Армении",
    koharBlog: "KOHAR blog",
    koharBlogContent: "Музыкальный блог, сайт симфонического оркестра и хора",
    affordableLuxuryTravel: "Affordable Luxury Travel",
    affordableLuxuryTravelContent: "Сайт туристического оператора класса люкс",
    meliq: "MELIQ",
    meliqContent: "Сайт компании по производству восточных украшений",
    yungo: "YUNGO",
    yungoContent: "Разработка мобильного приложения для VoIP-сервиса",
    pixpops: "Pixpops",
    pixpopsContent: "Платформа для заказа изображений, позволяющая пользователям выбирать фотографии для отправки для украшения их стен без забивания гвоздей. Система оплаты (PayPal, Stripe) Интеграция UPS",
    friendsWash: "FRIENDS WASH",
    friendsWashContent: "Онлайн-система обработки заказов. Система обработки заказов (панель администратора)",
    asurg: "<<АСУРГ>>",
    asurgContent: "Автоматическая система учета потребления газа",
    addedNEWBAN:"",
    popupAsurg: {
      point1: "1. О программе: Исключительные права на ПО «АСУРГ» принадлежат Обществу с ограниченной ответственностью «АйТиФайв»",
      point2: "2. Стоимость рассчитывается индивидуально, Для определения стоимости Вы можете связаться по телефону +37495235294 / почте info@itfive.am (Указать контакты для определения стоимости)",
      point3: "3. О функциональных характеристиках",
      point4: "4. Программное обеспечение является интернет-сервисом, дополнительные требования к установке программного обеспечения со стороны пользователя не требуются",
      point5: "5. Инструкции по эксплуатации программного обеспечения (руководство пользователя)."
    },
    friendsWashDetails: {
      actualSituation: "Фактическая ситуация с услугами мойки автомобилей и клиентами на карте",
      clientsList: "Список клиентов (с инструментами фильтрации по нескольким критериям)",
      liveOrders: "Список текущих заказов с дополнительной информацией",
      cooperationRequests: "Список запросов на сотрудничество (от автомоек)",
      feedbackRating: "Система отзывов и рейтингов как для клиентов, так и для автомоек",
      bookingSystem: "Система бронирования",
      blackList: "Черный список",
      notifications: "Система уведомлений",
      settings: "Настройки",
      onlineAppFunctionality: "Функциональность онлайн-приложения для автомоек и клиентов",
      mapView: "Просмотр автомоек на карте",
      clientLocation: "Определение местоположения клиента на карте для отображения ближайшей автомойки",
      bookService: "Бронирование услуги",
      createOrder: "Создание заказа немедленно на основе текущего местоположения",
      chooseService: "Выбор автомойки из списка (сортировка по расстоянию от клиента)",
      viewServiceLists: "Просмотр списков услуг и цен для каждой автомойки",
      chooseTime: "Выбор удобного времени из расписания",
      writeFeedback: "Написание отзыва и оценка автомойки"
    }
  }
};

const Recen = () => {
    const { language } = useLanguage();  // Use the useLanguage hook
    const [currentIndex, setCurrentIndex] = useState(0);
    const [rotate1, setRotate1] = useState(false);
    const [rotate2, setRotate2] = useState(false);
    const [rotate3, setRotate3] = useState(false);
    const [rotate4, setRotate4] = useState(false);
    const items = ['Item 1', 'Item 2'];

    const handleNextClick = () => {
        setCurrentIndex(prevIndex => Math.min(prevIndex + 1, items.length - 1));
    };

    const handlePrevClick = () => {
        setCurrentIndex(prevIndex => Math.max(prevIndex - 1, 0));
    };

    const [showFullContent, setShowFullContent] = useState(false);

    const toggleContent = () => {
        setShowFullContent(!showFullContent);
    };

    const handleClick1 = () => {
        setRotate1(!rotate1);
      };
    
    const handleClick2 = () => {
        setRotate2(!rotate2);
    };

    const handleClick3 = () => {
        setRotate3(!rotate3);
    };
    

    const handleClick4 = () => {
        setRotate4(!rotate4);
    };
    
    const t = translations[language];

    return (
        <div>
            <div className='News'>
                <div className='Newstext'>
                    <h2 className='Newstext1'>{t.recentWorks}</h2>
                    <p className='Newstext2'>
                        {showFullContent ? (
                            <>
                                {t.recentWorksContent} <a className='show' href="#popupASH" onClick={toggleContent}>
                                    {t.showLess}
                                </a>
                            </>
                        ) : (
                            <>
                                {t.recentWorksContent.split('. ').slice(0, 2).join('. ')}. <a className='show' href="#popupASH" onClick={toggleContent}>
                                    {t.showMore}
                                </a>
                            </>
                        )}
                    </p>
                </div>
            </div>
            <div className='Reactext'>
                <h1>{t.recentWorks}</h1>
                <p>
                    {t.recentWorksContent}
                </p>
            </div>

            <div className='flexs'>

                <div className='cards'>
                    <img src={img1} alt="" />
                    <div className='rec'>
                        <p className='r'>
                            {t.ldtTech}
                        </p>
                        <p className='r1'>
                            {t.ldtTechContent}
                        </p>
                    </div>

                    <div id='Index'>
                        <a className='read' href="#popup">{t.readMore}</a>
                    </div>
                    <div id='popup'>
                        <div>

                            <a className='pop' href="#index">X</a>
                            <h2 className='text1'>{t.ldtTech}</h2>
                            <p>
                                {t.ldtTechContent} <br />
                                — Armen Hovhannisyan
                                <img src={imgArray[currentIndex]} alt="" />
                                <br />
                            </p>
                            <div className="popup-navigation">
                                <button className='prevNext' onClick={handlePrevClick}>&laquo; Previous</button>
                                <button className='prevNext' onClick={handleNextClick}>Next &raquo;</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='cards'>
                    <img src={img4} alt="" />
                    <div className='rec'>
                        <p className='r'>
                            {t.utaxi}
                        </p>
                        <p className='r1'>
                            {t.utaxiContent}
                        </p>
                    </div>

                    <div id='Index1'>
                        <a className='read1' href="#popup1">{t.readMore}</a>
                    </div>

                    <div id='popup1'>
                        <div>
                            <a className='pop1' href="#index">X</a>
                            <h2 className='text1'>{t.utaxi}</h2>
                            <p>
                                {t.utaxiContent} <img src={imgArray1[currentIndex]} alt="" />
                            </p>
                            <div className="popup-navigation">
                                <button className='prevNext' onClick={handlePrevClick}> &laquo;Previous</button>
                                <button className='prevNext' onClick={handleNextClick}>Next &raquo;</button>
                            </div>

                        </div>
                    </div>
                </div>

                <div className='cards'>
                    <img src={img7} alt="" />
                    <div className='rec'>
                        <p className='r'>
                            {t.unionOfInformedCitizens}
                        </p>
                        <p className='r1'>
                            {t.unionOfInformedCitizensContent}
                        </p>
                    </div>

                    <div id='Index2'>
                        <a className='read2' href="#popup2">{t.readMore}</a>
                    </div>

                    <div id='popup2'>
                        <div>
                            <a className='pop2' href="#index">X</a>
                            <h2 className='text1'>{t.unionOfInformedCitizens}</h2>
                            <p>
                                {t.unionOfInformedCitizensContent} <br />
                                — Ashkhen Musheghyan
                                <img src={imgArray2[currentIndex]} alt="" />
                                <div className="popup-navigation">
                                    <button className='prevNext' onClick={handlePrevClick}>&laquo; Previous</button>
                                    <button className='prevNext' onClick={handleNextClick}>Next &raquo;</button>
                                </div>
                            </p>
                        </div>
                    </div>
                </div>

                <div className='cards'>
                    <img src={img10} alt="" />
                    <div className='rec'>
                        <p className='r'>
                            {t.cilicia}
                        </p>
                        <p className='r1'>
                            {t.ciliciaContent}
                        </p>
                    </div>
                </div>

                <div className='cards'>
                    <img src={img11} alt="" />
                    <div className='rec'>
                        <p className='r'>
                            {t.sixtAm}
                        </p>
                        <p className='r1'>
                            {t.sixtAmContent}
                        </p>
                    </div>
                </div>

                <div className='cards'>
                    <img src={img12} alt="" />
                    <div className='rec'>
                        <p className='r'>
                            {t.koharBlog}
                        </p>
                        <p className='r1'>
                            {t.koharBlogContent}
                        </p>
                    </div>
                </div>

                <div className='cards'>
                    <img src={img13} alt="" />
                    <div className='rec'>
                        <p className='r'>
                            {t.affordableLuxuryTravel}
                        </p>
                        <p className='r1'>
                            {t.affordableLuxuryTravelContent}
                        </p>
                    </div>
                </div>

                <div className='cards'>
                    <img src={img14} alt="" />
                    <div className='rec'>
                        <p className='r'>
                            {t.meliq}
                        </p>
                        <p className='r1'>
                            {t.meliqContent}
                        </p>
                    </div>
                </div>

                <div className='cards'>
                    <img src={img15} alt="" />
                    <div className='rec'>
                        <p className='r'>
                            {t.yungo}
                        </p>
                        <p className='r1'>
                            {t.yungoContent}
                        </p>
                    </div>
                </div>

                <div className='cards'>
                    <img src={img16} alt="" />
                    <div className='rec'>
                        <p className='r'>
                            {t.pixpops}
                        </p>
                        <p className='r1'>
                            {t.pixpopsContent}
                        </p>
                    </div>
                </div>

                <div className='cards'>
                    <img src={img17} alt="" />
                    <div className='rec'>
                        <p className='r'>
                            {t.friendsWash}
                        </p>
                        <p className='r1'>
                            {t.friendsWashContent}
                        </p>
                    </div>

                    <div id='Index3'>
                        <a className='read3' href="#popup3">{t.readMore}</a>
                    </div>

                    <div id='popup3'>
                        <div>
                            <a className='pop3' href="#index">X</a>
                            <h2 className='text1'>{t.friendsWash}</h2>
                            <p>
                                <p className='bold'>{t.friendsWashContent}</p>
                                <li>{t.friendsWashDetails.actualSituation}</li>
                                <li>{t.friendsWashDetails.clientsList}</li>
                                <li>{t.friendsWashDetails.liveOrders}</li>
                                <li>{t.friendsWashDetails.cooperationRequests}</li>
                                <li>{t.friendsWashDetails.feedbackRating}</li>
                                <li>{t.friendsWashDetails.bookingSystem}</li>
                                <li>{t.friendsWashDetails.blackList}</li>
                                <li>{t.friendsWashDetails.notifications}</li>
                                <li>{t.friendsWashDetails.settings}</li>
                                <p className='bold'>{t.friendsWashDetails.onlineAppFunctionality}</p>
                                <li>{t.friendsWashDetails.mapView}</li>
                                <li>{t.friendsWashDetails.clientLocation}</li>
                                <li>{t.friendsWashDetails.bookService}</li>
                                <li>{t.friendsWashDetails.createOrder}</li>
                                <li>{t.friendsWashDetails.chooseService}</li>
                                <li>{t.friendsWashDetails.viewServiceLists}</li>
                                <li>{t.friendsWashDetails.chooseTime}</li>
                                <li>{t.friendsWashDetails.writeFeedback}</li>
                            </p>
                        </div>
                    </div>
                </div>

                <div className='cards'>
                    <img id='ASURG' src={img22} alt="" />
                    <div className='rec'>
                        <p className='r'>
                        {t.asurg}
                        </p>
                        <p className='r1'>
                            {t.asurgContent}
                        </p>
                    </div>

                    <div id='Index4'>
                        <a className='read4' href="#popup4">{t.readMore}</a>
                    </div>

                    <div id='popup4'>
                        <div>
                            <a className='pop4' href="#index">X</a>
                            <h2 className='text1'>{t.asurg}</h2> 
                            <p className='dow'>
                                {t.popupAsurg.point1}
                                <a className='donwlod' href={fail1} download="file.docx">
                                    <img className={rotate1 ? 'rotate' : ''}  src={img21} alt=""  onClick={handleClick1}/>
                                </a>
                            </p>
                            <p>
                                {t.popupAsurg.point2}                          
                            </p>
                            <p className='dow'> 
                                {t.popupAsurg.point3}
                                <a className='donwlod' href={fail3} download="file.docx">
                                  <img className={rotate2 ? 'rotate' : ''} src={img21} alt="" onClick={handleClick2} />
                                </a>
                            </p>
                            <p className='dow'>
                                {t.popupAsurg.point4}
                            </p>
                            <p className='dow'>
                                {t.popupAsurg.point5}
                                <a className='donwlod' href={fail4} download="file.docx">
                                  <img  className={rotate4 ? 'rotate' : ''} src={img21} alt="" onClick={handleClick4} />
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <footer className='foott foo'>{t.footer}</footer>

        </div>
    );
}

export default Recen;
