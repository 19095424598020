import React, { createContext, useState, useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './componetns/Home/home';
import Navbar from "./componetns/Navbar/navbar";
import Recentwork from './componetns/Recentwork/recen';
import Contact from './componetns/Contact/contact';

const LanguageContext = createContext();

export const useLanguage = () => {
  return useContext(LanguageContext);
};

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');

  const switchLanguage = (lang) => {
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, switchLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

function App() {
  return (
    <LanguageProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/recentwork" element={<Recentwork />} />
          
        </Routes>
      </Router>
    </LanguageProvider>
  );
}

export default App;
