import './navbar.css';
import { NavLink } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";
import useNavbar from './useNavbar';
import img1 from './imgNavbar/[ITfive].png';
import { useLanguage } from '../../App';

const translations = {
  en: {
    home: "HOME",
    recentWorks: "RECENT WORKS",
    contactUs: "CONTACT US",
  },
  ru: {
    home: "ГЛАВНАЯ",
    recentWorks: "ПРОЕКТЫ",
    contactUs: "КОНТАКТЫ",
  }
};

const Navbar = () => {
  const { 
    showMenu,
    toggleMenu,
    closeMenuOnMobile,
    handleScrollToTop,
   } = useNavbar();
   
  const { language, switchLanguage } = useLanguage();
  const { home, recentWorks, contactUs } = translations[language];

  return (
    <header className="header">
      <nav className="nav container">
        <div className="nav__logo">
          <img src={img1} alt="" />
        </div>

        <div
          className={`nav__menu ${showMenu ? "show-menu" : ""}`}
          id="nav-menu"
        >
          <ul className="nav__list">
            <li className="nav__item">
              <NavLink 
                to="/" 
                className="nav__link" 
                onClick={() => {
                  closeMenuOnMobile();
                  handleScrollToTop();
                }}
              >
                {home}
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink 
                to="/recentwork" 
                className='nav__link'
                onClick={() => {
                  closeMenuOnMobile();
                  handleScrollToTop();
                }}
              >
                {recentWorks}
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink 
                to="/contact" 
                className='nav__link'  
                onClick={() => {
                  closeMenuOnMobile();
                  handleScrollToTop();
                }}
              >
                {contactUs}
              </NavLink>
            </li>
          </ul>
          
          <div className="nav__close" id="nav-close" onClick={toggleMenu}>
            <IoClose />
          </div>
        </div>

        <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
          <IoMenu />
        </div>
        
        <div className="nav__language">
          <button className="nav__language-btn" onClick={() => switchLanguage('en')}>EN</button>
          <button className="nav__language-btn" onClick={() => switchLanguage('ru')}>RU</button>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
