import React from 'react';
import imgg1 from './imgHome/arrow.white.svg';
import imgg2 from './imgHome/Group 29.png';
import imgg4 from './imgHome/DigitalMarketing.png';
import imgg5 from './imgHome/Design.png';
import imgg6 from './imgHome/Hosting.png';
import imgg7 from './imgHome/MobileApps.png';
import imgg8 from './imgHome/ProjectManagement.png';
import imgg9 from './imgHome/Web.png';
import imgg10 from './imgHome/Group 30.png';
import './home.css';
import { useLanguage } from '../../App';

const translations = {
    en: {
        header: "We Are Expert In Web",
        intro: "Our company has been established in 2014. Since then We have implemented number of projects in web development, digital marketing, graphic design, project management, mobile apps development and cloud technology.",
        services: [
            {
                title: "Project Management",
                description: "Time management, strategy planning and tracking"
            },
            {
                title: "Mobile Apps",
                description: "Web based applications, Native coding"
            },
            {
                title: "Cloud, Hosting",
                description: "Web hosting, VPS, VDS, Cloud servers"
            },
            {
                title: "Web Development",
                description: "Web content development, client-side/server-side scripting"
            },
            {
                title: "Design",
                description: "Web design, Graphic design, 3D design"
            },
            {
                title: "Digital Marketing",
                description: "Social Platforms, Google"
            }
        ]
    },
    ru: {
        header: "Мы Эксперты В Вебе",
        intro: "Наша компания была основана в 2014 году. С тех пор мы реализовали ряд проектов в области веб-разработки, цифрового маркетинга, графического дизайна, управления проектами, разработки мобильных приложений и облачных технологий.",
        services: [
            {
                title: "Управление проектами",
                description: "Управление временем, планирование стратегии и отслеживание"
            },
            {
                title: "Мобильные приложения",
                description: "Веб-приложения, Нативное кодирование"
            },
            {
                title: "Облако, Хостинг",
                description: "Веб-хостинг, VPS, VDS, Облачные серверы"
            },
            {
                title: "Веб-разработка",
                description: "Разработка веб-контента, клиентская/серверная скриптовая разработка"
            },
            {
                title: "Дизайн",
                description: "Веб-дизайн, Графический дизайн, 3D дизайн"
            },
            {
                title: "Цифровой маркетинг",
                description: "Социальные платформы, Google"
            }
        ]
    }
};

const Home = () => {
    const { language } = useLanguage();
    const { header, intro, services } = translations[language];

    return (
        <div>
            <div className='img'>
                <h3 id='divise'><img src={imgg2} alt="" /></h3>
                <h3 id='mobail'><img src={imgg10} alt="" /></h3>
                <div className='wrap'>
                    <a href="#hvg">
                        <img src={imgg1} alt="" />
                    </a>
                </div>
            </div>

            <div id='hvg'></div>
            <div className='aboutUS'>
                <div className='about'>
                    <div className='text'>
                        <p className='abo1'></p>
                        <h1 className='hbo1'>{header}</h1>
                        <p className='abo2'>{intro}</p>
                    </div>
                </div>
            </div>

            <div className='flex'>
                {services.map((service, index) => (
                    <div className={`card${index + 1}`} key={index}>
                        <img src={imgg4} alt="" /> <span>{service.title}</span>
                        <div className='ser'>
                            <p className='p'>{service.title}</p>
                            <p className='p1'>{service.description}</p>
                        </div>
                    </div>
                ))}
            </div>
            <footer className='foo'>Copyright © 2024 All rights reserved | This website is made with by ITFIVE</footer>
        </div>
    );
}

export default Home;
