import './contact.css';
import useContact from './useContact';
import eb from "./eb.png";
import { useLanguage } from '../../App';

const translations = {
  en: {
    getInTouch: "Get In Touch",
    namePlaceholder: "Name",
    emailPlaceholder: "Email address",
    subjectPlaceholder: "Subject",
    messagePlaceholder: "Message",
    sendButton: "Send",
    contactUs: "Contact Us",
    address: "Address: H. Hakobyan 3/18, Yerevan",
    email: "E-mail: info@itfive.am",
    phone: "Tel: +374 77 957795"
  },
  ru: {
    getInTouch: "Свяжитесь с нами",
    namePlaceholder: "Имя",
    emailPlaceholder: "Адрес электронной почты",
    subjectPlaceholder: "Тема",
    messagePlaceholder: "Сообщение",
    sendButton: "Отправить",
    contactUs: "Контакты",
    address: "Адрес: Х. Акопян 3/18, Ереван",
    email: "Электронная почта: info@itfive.am",
    phone: "Тел: +374 77 957795"
  }
};

const Contact = () => {
  const { language } = useLanguage();
  const {
    getInTouch,
    namePlaceholder,
    emailPlaceholder,
    subjectPlaceholder,
    messagePlaceholder,
    sendButton,
    contactUs,
    address,
    email,
    phone
  } = translations[language];

  const {
    onMrg,
    onSubmit,
    register,
    handleSubmit,
    alertInfo,
    setAlertInfo,
    errors,
  } = useContact();
   
  return (
    <div className='ContactForms'>
      <div className='containerr'>
        <div className='mapDiv'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3047.4007912620655!2d44.48813072653425!3d40.20014676879198!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abd6bc3453033%3A0x5257eebfb4e1b97d!2s3%20Hakob%20Hakobyan%20St%2C%20Yerevan%200033!5e0!3m2!1sen!2sam!4v1713860026263!5m2!1sen!2sam" width="500" height="544" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>

        <div className='row'>
          <div className='col-12 text-center'>
            <div className='contactForm'>
              <form id='contact-form' onClick={onMrg} onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className='row formRow'>
                  <h1 id='ContactFormH1'>{getInTouch}</h1>
                  <div className='col-6'>
                    <input
                      type='text'
                      name='name'
                      {...register('name', {
                        required: { value: true, message: 'Please enter your name' },
                        maxLength: {
                          value: 30,
                          message: 'Please use 30 characters or less'
                        }
                      })}
                      className='form-control formInput'
                      placeholder={namePlaceholder}
                    ></input>
                    {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
                  </div>
                  <div className='col-6'>
                    <input
                      type='email'
                      name='email'
                      {...register('email', {
                        required: true,
                        pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                      })}
                      className='form-control formInput'
                      placeholder={emailPlaceholder}
                    ></input>
                    {errors.email && (
                      <span className='errorMessage'>Please enter a valid email address</span>
                    )}
                  </div>
                </div>
                <div className='row formRow'>
                  <div className='col'>
                    <input
                      type='text'
                      name='subject'
                      {...register('subject', {
                        required: { value: true, message: 'Please enter a subject' },
                        maxLength: {
                          value: 75,
                          message: 'Subject cannot exceed 75 characters'
                        }
                      })}
                      className='form-control formInput'
                      placeholder={subjectPlaceholder}
                    ></input>
                    {errors.subject && (
                      <span className='errorMessage'>{errors.subject.message}</span>
                    )}
                  </div>
                </div>
                <div className='row formRow'>
                  <div className='col'>
                    <input type="text" placeholder={messagePlaceholder} />
                    {errors.message && <span className='errorMessage'>Please enter a message</span>}
                  </div>
                </div>
                <button id='sbmBtn' className='submit-btn' type='submit'>
                  {sendButton}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {alertInfo.display && (
        <div
          className={`alert alert-${alertInfo.type} alert-dismissible mt-5`}
          role='alert'
        >
          {alertInfo.message}
          <button
            type='button'
            className='btn-close'
            data-bs-dismiss='alert'
            aria-label='Close'
            onClick={() =>
              setAlertInfo({ display: false, message: '', type: '' })
            }
          ></button>
        </div>
      )}
      <div id='footer'>
        <div id='aboutUs'>
          <p id='abus'>{contactUs}</p>
          <p id='qq'>
            {address}
            <br />
            {email}
            <br />
            {phone}
          </p>
        </div>
        <img src={eb} alt="eb" />
      </div>
      <footer className='foo foo1'>Copyright © 2024 All rights reserved | This website is made with by ITFIVE</footer>
    </div>
  );
};

export default Contact;
